$gray: #f0eded;
$black: #000000;
$lightgray: #f7f7f7;
$screensaverBg: #9d90fc;

.hidden {
    display: none !important;
}

.__react_component_tooltip {
    padding: 5px 12px;
}

.no-events {
    pointer-events: none !important;
}

.mont {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.tiny-mofos {
    font-size: 0.7em;
}

.mont-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
}

.work300 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
}

.work400 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
}

.work600 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
}

.invert {
    filter: invert(100);
    .fullScreen,
    .collage-container {
        filter: invert(100);
    }
}

.rotate {
    position: absolute;
    z-index: 99999;
    transform: translateY(-100%);
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    transition: all 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    img {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: jump;
        animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
}

.white {
    background-color: white;
}

.bordered {
    img {
        border: 1px solid black;
    }
}

.black {
    background-color: black;
    color: white;
}
#story {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: white;
    border: 1px solid black;
    padding: 5px;
    font-size: 0.8em;
}

svg * {
    transform-origin: center; /* or transform-origin: 50% */
    transform-box: fill-box;
}

#pancake {
    -webkit-transition: -webkit-transform 5s ease-in-out;
    transition: transform 5s ease-in-out;
    &:hover {
        transform-origin: center;
        transform: rotate(-12440deg);
    }
}

#moon {
    -webkit-transition: -webkit-transform 8s ease-in-out;
    transition: transform 8s ease-in-out;
    &:hover {
        transform-origin: center;
        transform: rotate(120deg);
    }
}

#jelly {
    &:hover {
        animation-name: floating;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
}

#pasta {
    -webkit-transition: -webkit-transform 8s ease-in-out;
    transition: transform 20s ease-in-out;
    &:hover {
        transform-origin: center;
        transform: scale(10);
    }
}
#cat {
    -webkit-transition: -webkit-transform 1s ease-in-out;
    transition: all 1s ease-in-out;
    &:hover {
        transform: translate(0, 40px) rotate(-1deg);
        opacity: 0.5;
    }
}
.loading {
    background-color: black;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loader {
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .balls {
            // .LoaderBalls__wrapper__item
            $anim-drt: 0.4s;
            $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $screensaverBg;

            &:nth-child(1) {
                animation: bouncing $anim-drt alternate infinite $anim-ease;
            }

            &:nth-child(2) {
                animation: bouncing $anim-drt $anim-drt/4 alternate infinite
                    $anim-ease backwards;
            }

            &:nth-child(3) {
                animation: bouncing $anim-drt $anim-drt/2 alternate infinite
                    $anim-ease backwards;
            }
        }
    }
}

.screensaver {
    background-color: $screensaverBg;
    background-image: url(../images/screensaver/jell-1.png),
        url(../images/screensaver/jell-2.png),
        url(../images/screensaver/jell-3.png);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999999999;
    -webkit-animation: snow 2000s linear infinite;
    -moz-animation: snow 2000s linear infinite;
    -ms-animation: snow 2000s linear infinite;
    animation: snow 2000s linear infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.black-overlay {
    z-index: 100;
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-animation: flickerAnimation 1.4s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
    p {
        color: white;
        font-size: 0.8em;
    }
}

html,
body,
#root,
.app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: url('../images/cursor.png'), auto;
}

html,
body,
#root {
    overflow: hidden;
    position: relative;
}

.tooltip {
    color: black !important;
    background-color: white !important;
    border: 1px solid black;
    border-radius: 0px !important;
    z-index: 100000;
    font-size: 0.7em;
    &:after {
        opacity: 0 !important;
    }
}

.app {
    background: #fcacd7;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.handle {
    .red-folder,
    .blue-folder {
        text-align: center;
        img {
            pointer-events: none;
            width: 60px;
        }
    }

    .title {
        font-size: 0.8em;
        text-align: center;
        margin-top: 5px;
        text-transform: lowercase;
    }

    .radio {
        img {
            pointer-events: none;
            width: 90px;
        }
    }
    .spotify-iframe{
        border-radius: 12px;
        width: 400px;
    }
    .red-button {
        img {
            pointer-events: none;
            height: 90px;
        }
    }

    .bin {
        img {
            pointer-events: none;
            height: 90px;
        }
    }
}

.loadingCollage {
    height: 450px;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../images/collage.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bouncy {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: bouncy;
    animation-name: bouncy;
}

.show-red-button-animation {
    animation: animate 0.5s;
}

@keyframes animate {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1, 0.002);
    }
    35% {
        transform: scale(0.2, 0.002) opacity 1;
    }
    40% {
        transform: scale(0.2, 0.002) opacity 0;
    }
    55% {
        transform: scale(1, 0.002);
        opacity: 1;
    }
    65% {
        transform: scale(1, 1);
    }
}

.trgif {
    width: 400px;
    img {
        width: 100%;
    }
}

.ugly {
    width: 250px;
    img {
        width: 100%;
        pointer-events: none;
        border-top: 20px solid #f9ebcd;
        border-radius: 5px;
    }
}

.kota {
    img {
        pointer-events: none;
    }
    .title {
        font-size: 0.8em;
        text-align: center;
        margin-top: 5px;
        text-transform: lowercase;
    }
}
.desktop {
    width: 100%;
    height: 100%;
    .active {
        filter: drop-shadow(5px -5px 0px #0000004a);
    }
    .postit-container,
    .postit-two-container {
        height: 250px;
        background-color: #fdbf7b;
        box-shadow: 0px 0px 10px #00000038;
        border-radius: 5px;
        .header {
            height: 20px;
            background-color: #fca949;
            margin-bottom: 10px;
            border-radius: 5px 5px 0 0;
            svg {
                height: 13px;
                margin-left: 6px;
                color: #ffb16b;
            }
        }
        p {
            padding: 0 10px;
            font-size: 0.8em;
            margin: 0;
        }
    }
    .mother,
    .father {
        background: white;
        border: 1px solid black;
        box-shadow: -15px 15px 1px #0000002b;
        .topbar {
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            font-size: 0.9em;
            align-items: center;
            .close img,
            .maximise img {
                background: $gray;
                padding: 5px;
            }
            .collage-title {
                opacity: 0;
            }
            div p {
                margin: 0;
            }
            .title span {
                background: $gray;
                padding: 2px 10px;
            }
            .close,
            .items {
                text-align: left;
            }
            .maximise,
            .available {
                text-align: right;
            }
            .title,
            .disk {
                text-align: center;
            }
        }
        .bar {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            font-size: 0.8em;
            align-items: center;
            .close img,
            .maximise img {
                background: $gray;
                padding: 5px;
            }
            div p {
                margin: 0;
            }
            .title span {
                background: $gray;
                padding: 0 13px;
            }
            .close,
            .items {
                text-align: left;
            }
            .maximise,
            .available {
                text-align: right;
            }
            .title,
            .disk {
                text-align: center;
            }
        }
        .topbar {
            background: #f0eded;
            border-bottom: 1px solid black;
        }
        .bar {
            border-bottom: 1px solid black;
            padding: 5px;
        }
        .emma-po {
            pointer-events: none;
            background: rgb(0, 0, 0);
        }
        .np-container {
            padding: 0;
            .project {
                .star {
                    width: 70px;
                    position: absolute;
                    right: -30px;
                    top: -30px;
                    transform: rotate(-10deg);
                    z-index: 1;
                    filter: sepia(1);
                    img {
                        width: 100%;
                    }
                }
            }
            .showreel-c {
                background: black;
                video {
                    display: block;
                }
                .react-player__preview {
                    min-height: 337px !important;
                }
            }
            .content {
                display: flex;
                img {
                    width: 100%;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .container {
            padding: 40px 30px 40px 30px;
            .projects {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                justify-items: center;
                .animation {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    img {
                        height: 55px;
                        margin-top: -30px;
                    }
                }
                div {
                    img {
                        height: 80px;
                    }
                }
            }

            .documents {
                display: grid;
                grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
                justify-items: center;
                .notebook {
                    margin-top: 5px;
                }
                div {
                    img {
                        height: 80px;
                    }
                }
            }
        }
        .notebook-container {
            height: 400px;
            padding: 30px;
            font-size: 0.9em;
            overflow: auto;
            text-align: center;
            line-height: 1.4em;

            p {
                margin: 0;
            }
            .avatar {
                height: 240px;
                margin-bottom: 20px;
                animation: avatario 2s infinite alternate;
                video {
                    clip-path: circle(40% at 50% 50%);
                    height: 100%;
                }
            }
        }
        .legal-container {
            max-height: 300px;
            padding: 30px;
            font-size: 0.9em;
            overflow: auto;
            line-height: 1.4em;
            p {
                margin: 0;
            }
        }
        .collage-container {
            .image {
                svg {
                    display: block;
                }
            }
        }
        .doc-container {
            // padding-bottom: 50px;
            .list {
                .files {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    img {
                        height: 20px;
                    }
                    span {
                        font-size: 0.8em;
                        margin-left: 10px;
                    }
                }
                :nth-child(odd) {
                    background-color: $lightgray;
                }
            }
        }
        .trash-container {
            // padding-bottom: 50px;
            .list {
                .files {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    img {
                        height: 20px;
                    }
                    span {
                        font-size: 0.8em;
                        margin-left: 10px;
                    }
                }
                :nth-child(odd) {
                    background-color: $lightgray;
                }
            }
        }
        .todo-container {
            .list {
                .files {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    svg {
                        margin: 0 10px;
                    }
                    img {
                        height: 20px;
                    }
                    span {
                        font-size: 0.8em;
                    }
                }
                .done {
                    span {
                        text-decoration: line-through;
                    }
                }
                :nth-child(odd) {
                    background-color: $lightgray;
                }
            }
        }
        .error-container {
            .message {
                padding: 20px;
                font-size: 0.8em;
                text-align: center;
            }
            .button {
                padding: 10px;
                text-align: right;
                button {
                    padding: 5px 20px;
                    text-align: center;
                    border: 1px solid black;
                    background-color: $gray;
                    &:focus {
                        outline: none;
                    }
                    &:active {
                        background-color: $black;
                    }
                }
            }
        }
    }
    .fullScreen {
        position: absolute;
        z-index: 20000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        .return {
            height: 50px;
            position: absolute;
            top: 30px;
            left: 30px;
            width: 50px;
            z-index: 3000;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
            }
        }
        .hippo-container {
            width: 100%;
            height: 100vh;
            position: relative;
            overflow: scroll;
            .child {
                margin: 50px 0;
                width: 100%;
                min-height: 70vh;
                display: flex;
                flex-direction: row;
                color: white;
                justify-content: center;
                align-items: center;
                .three-in-a-row {
                    padding: 40px;
                }
                .one-in-a-row {
                    img {
                        border: 1px solid #ffffff36;
                        width: 75%;
                    }
                }

                .video-player {
                    // border: 1px solid rgb(48, 48, 48);

                    .imagePreview {
                        width: 100%;
                        height: 100%;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .left {
                    flex-basis: 0;
                    flex-grow: 2;
                    padding: 70px;
                    a {
                        color: white;
                    }
                    .title {
                        font-size: 1.3em;
                        font-weight: 700;
                        margin-bottom: 15px;
                        p {
                            margin-bottom: 10px;
                        }
                    }
                    .text {
                        font-size: 1em;
                        p {
                            margin: 0;
                            line-height: 22px;
                        }
                    }
                }
                .right {
                    flex-basis: 0;
                    flex-grow: 3;
                    padding: 70px;
                }
                .three-in-a-row {
                    display: flex;
                    flex-direction: row;
                    .child {
                        flex-basis: 0;
                        flex-grow: 1;
                        border: 1px solid #ffffff36;
                        margin: 10px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .parallax-cont {
            display: flex;
            align-items: center;
            img {
                width: 100%;
                // box-shadow: 0px 0px 100px #0000000d;
            }
            .parallax-text-container-white {
                color: white;
            }
            .parallax-text-container-black {
                color: black;
                font-size: 1em;
                line-height: 1.3em;
                .title {
                    font-size: 1.3em;
                    font-weight: 700;
                    margin-bottom: 15px;
                    p {
                        margin-bottom: 10px;
                    }
                }
                .text {
                    font-size: 1em;
                    p {
                        margin: 0;
                        line-height: 22px;
                    }
                }
            }
            .parallax-center-text {
                display: block;
                width: 42%;
                margin-left: 29%;
                margin-top: -20%;
                text-align: center;
                .links {
                    .container {
                        display: flex;
                        justify-content: center;
                        padding: 20px;
                        font-size: 0.8em;
                        div {
                            width: 35px;
                            height: 35px;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                        a {
                            color: black;
                            opacity: 0.7;
                            transition: all 0.3s;
                            width: 35px;
                            background: white;
                            border-radius: 50%;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin: 0;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .parallax-right {
                display: block;
                width: 50%;
                margin-left: 45%;
            }
            .parallax-right-smaller {
                display: block;
                width: 40%;
                margin-left: 55%;
            }
            .parallax-left {
                display: block;
                width: 50%;
                margin-left: 5%;
            }
            .parallax-left-bigger {
                display: block;
                width: 60%;
                margin-left: 5%;
            }
            .parallax-left-smaller {
                display: block;
                width: 40%;
                margin-left: 5%;
            }
            .parallax-right-bigger {
                display: block;
                width: 70%;
                margin-left: 25%;
            }
            .parallax-center {
                width: 70%;
                margin-left: 15%;
            }
            .parallax-center-small {
                width: 50%;
                margin-left: 25%;
            }
            .parallax-center-smaller {
                width: 40%;
                margin-left: 30%;
            }
            .parallax-center-bigger {
                width: 80%;
                margin-left: 10%;
            }
            .parallax-full-screen {
                width: 100%;
                margin-left: 0%;
            }
        }
        .videoContainer {
            width: 100%;
            height: 100%;
            .imagePreview {
                width: 100%;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 400px;
            color: white;
            .on-point,
            .on-point-animation {
                position: relative;
                top: 0;
                left: 0;
                height: 100%;
                background: rgba(0, 0, 0, 0.76);
                transition: 0.4s;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
            }
            .slide-left {
                opacity: 0;
                left: -400px;
            }
            .slide-top {
                opacity: 0;
                top: -150px;
            }
            .title,
            .text {
                padding: 0 20px;
            }
            .title {
                display: flex;
                justify-content: left;
                align-items: baseline;
            }
            .links {
                .container {
                    display: flex;
                    padding: 20px;
                    font-size: 0.7em;
                    div {
                        cursor: pointer;
                    }
                    a {
                        color: white;
                        opacity: 0.7;
                        transition: opacity 0.3s;
                        margin-right: 10px;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background: #6f6f6f8c;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
            .title {
                font-size: 1.3em;
                font-weight: 700;
                p {
                    margin-bottom: 10px;
                }
            }
            .text {
                font-size: 1em;
                p {
                    margin: 0;
                    line-height: 22px;
                }
            }
        }
        .graphics-text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            background: rgba(0, 0, 0, 0.386);
            color: white;
            padding: 20px 100px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            transition: 0.4s;
            pointer-events: none;
            .title {
                p,
                h4 {
                    margin: 0;
                }
            }
        }
        .animations-text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            background: rgba(0, 0, 0, 0.386);
            color: white;
            padding: 20px 100px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            transition: 0.4s;
            pointer-events: none;
            .title {
                p,
                h4 {
                    margin: 0;
                }
            }
        }
        .photographies-root {
            position: relative;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .my-projects-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            // .tran-up{
            //   transform: translateY(-100%);
            // }
            .dots-container {
                position: absolute;
                z-index: 100;
                top: 50%;
                right: 10px;
                .dot {
                    width: 10px;
                    height: 10px;
                    margin: 10px;
                    background: rgba(255, 255, 255, 0.146);
                    transition: all 1s ease-in-out;
                    border-radius: 50%;
                    box-shadow: 0px 0px 20px #00000063;
                }
                .active-dot {
                    background: rgba(255, 255, 255, 0.548);
                    transition: all 1s ease-in-out;
                }
            }
            .hidden-down {
                transform: translateY(100%);
            }
            .project {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ccc;
                position: absolute;
                transition: transform cubic-bezier(0.72, 0.14, 0.46, 0.89) 0.5s;
                transform-origin: top;
                .body {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    .scroll-container {
                        position: absolute;
                        bottom: 0;
                        font-size: 0.8em;
                        animation: float 3s infinite;
                        transition-duration: 2s;
                        cursor: pointer;
                    }
                    .hidden-scroll {
                        opacity: 0;
                    }
                    .preview {
                        height: 60%;
                        width: 100%;
                        text-align: center;
                        transition: all ease-in-out 1s;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            cursor: pointer;
                        }
                    }
                    .text {
                        margin-top: 20px;
                        transition: all ease-in-out 1s;
                    }
                    .hide-a-bit {
                        transform: translateY(100px);
                        opacity: 0;
                    }
                }
            }
        }
        .graphics-container {
            position: relative;
            left: 0;
            top: 0;
            height: 100vh;
            overflow-x: auto;
            background: white;
            // &::-webkit-scrollbar {
            //   display: none;
            // }
            .image {
                max-width: 100%;
                text-align: center;
                &:first-child {
                    margin-top: 100px;
                }
                img {
                    width: 60%;
                    margin-bottom: 100px;
                    box-shadow: 0px 0px 20px #54545433;
                    transition: 0.5s transform;
                    transform-origin: center center;
                    &:hover {
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }
                }
            }
        }
        .animations-container {
            position: relative;
            left: 0;
            top: 0;
            height: 100vh;
            overflow-x: auto;
            background: white;
            // &::-webkit-scrollbar {
            //   display: none;
            // }
            .image {
                max-width: 100%;
                text-align: center;
                &:first-child {
                    margin-top: 100px;
                }
                img {
                    width: 60%;
                    margin-bottom: 100px;
                    box-shadow: 0px 0px 50px #0000000f;
                    transition: 0.5s transform;
                    transform-origin: center center;
                    &:hover {
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.nav {
    background: #e0e0e0;
    height: 32px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #1a1a1a;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .left {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-left: 35px;
        div {
            margin-top: 4px;
            font-size: 0.9em;
        }
        .name {
            padding: 0 10px 0 14px;
        }
        .about {
            margin-left: 5px;
        }
        .help-button {
            &:hover {
                div {
                    transform: translateX(100px);
                }
            }
        }
        .icon img {
            height: 24px;
            &:hover {
                animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            }
        }
        .dropdown {
            position: relative;
            display: inline-block;
            .dropbtn {
                margin-top: 3px;
                padding: 0px 6px;
                font-size: 0.9em;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                top: 31px;
                background-color: $gray;
                min-width: 120px;
                z-index: 99999;
                border-left: 1px solid black;
                border-right: 1px solid black;
                border-bottom: 1px solid black;
                a {
                    color: black;
                    padding: 3px 10px;
                    text-decoration: none;
                    display: block;
                    &:hover {
                        background-color: $black;
                        color: white;
                    }
                }
            }
        }
        .dropdown:hover {
            .dropdown-content {
                display: block;
            }
        }
    }

    .right {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
        div {
            margin-top: 3px;
            padding: 0px 6px;
            font-size: 1.1em;
        }
        .battery img {
            height: 15px;
        }
        .linkedin svg {
            color: #0077b5;
        }
        .tumblr svg {
            color: white;
        }
        .vimeo svg {
            color: #26323d;
        }
        .email {
            img {
                height: 15px;
                margin-top: 4px;
            }
        }
        .time {
            font-size: 0.9em;
        }
    }
}

//ANIMATIONS
.bncabt {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1, 1) translateY(0);
    }
    10.2% {
        transform: scale(1.1, 0.9) translateY(0);
        filter: grayscale(100%);
        mix-blend-mode: plus-lighter;
    }
    11% {
        transform: scale(1.1, 0.9) translate(100px, 100px);
    }
    20% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29.8% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    30% {
        transform: scale(1, 1) rotate(88deg) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    42% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    44% {
        transform: scale(1, 1) translateY(0);
    }
    50% {
        transform: scale(1, 1) translateY(0);
        mix-blend-mode: saturation;
    }
    55% {
        transform: scale(1.1, 0.9) translateY(0);
        mix-blend-mode: difference;
    }
    67% {
        transform: scale(1.1, 0.9) translate(-400px, 200px);
    }
    68% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    69% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
        mix-blend-mode: plus-lighter;
    }
    80% {
        transform: scale(1, 1) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    89% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(100%);
    }
    95% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@-o-keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1, 1) translateY(0);
    }
    10.2% {
        transform: scale(1.1, 0.9) translateY(0) rotate(100deg);
        filter: grayscale(100%);
    }
    11% {
        transform: scale(1.1, 0.9) translate(100px, 100px);
    }
    20% {
        transform: scale(0.9, 1.1) translateY(-20px);
        mix-blend-mode: difference;
    }
    29% {
        transform: scale(0.9, 1.1) translateY(-20px) rotate(-100deg);
    }
    29.8% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    30% {
        transform: scale(1, 1) rotate(88deg) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    42% {
        transform: scale(1, 1) translateY(0) rotate(10deg);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    44% {
        transform: scale(1, 1) translateY(0);
    }
    50% {
        transform: scale(1, 1) translateY(0) rotate(-100deg);
    }
    55% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    67% {
        transform: scale(1.1, 0.9) translate(-400px, 200px);
    }
    68% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    69% {
        transform: scale(1.05, 0.95) translateY(0) rotate(300deg);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    80% {
        transform: scale(1, 1) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    89% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(100%);
    }
    95% {
        transform: scale(1, 1) translateY(0) rotate(-300deg);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@-moz-keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1, 1) translateY(0);
    }
    10.2% {
        transform: scale(1.1, 0.9) translateY(0);
        filter: grayscale(100%);
    }
    11% {
        transform: scale(1.1, 0.9) translate(100px, 100px);
    }
    20% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29.8% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    30% {
        transform: scale(1, 1) rotate(88deg) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    42% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    44% {
        transform: scale(1, 1) translateY(0);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    55% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    67% {
        transform: scale(1.1, 0.9) translate(-400px, 200px);
    }
    68% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    69% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    80% {
        transform: scale(1, 1) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    89% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(100%);
    }
    95% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1, 1) translateY(0);
    }
    10.2% {
        transform: scale(1.1, 0.9) translateY(0);
        filter: grayscale(100%);
    }
    11% {
        transform: scale(1.1, 0.9) translate(100px, 100px);
    }
    20% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    29.8% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    30% {
        transform: scale(1, 1) rotate(88deg) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    42% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    44% {
        transform: scale(1, 1) translateY(0);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    55% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    67% {
        transform: scale(1.1, 0.9) translate(-400px, 200px);
    }
    68% {
        transform: scale(0.9, 1.1) translateY(-20px);
    }
    69% {
        transform: scale(1.05, 0.95) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    80% {
        transform: scale(1, 1) translateY(-7px);
        filter: drop-shadow(16px 16px 0 red) invert(75%);
    }
    89% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(100%);
    }
    95% {
        transform: scale(1, 1) translateY(0);
        filter: drop-shadow(0 0 0 red) invert(0%);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.2% {
        opacity: 1;
    }
    10.7% {
        opacity: 0;
    }
    44% {
        opacity: 0;
    }
    45% {
        opacity: 0;
        mix-blend-mode: hard-light;
    }
    46% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    56% {
        opacity: 1;
    }
    59% {
        opacity: 0;
        mix-blend-mode: saturation;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        mix-blend-mode: soft-light;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.2% {
        opacity: 1;
    }
    10.7% {
        opacity: 0;
    }
    44% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    46% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    56% {
        opacity: 1;
    }
    59% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.2% {
        opacity: 1;
    }
    10.7% {
        opacity: 0;
    }
    44% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    46% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    56% {
        opacity: 1;
    }
    59% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.2% {
        opacity: 1;
    }
    10.7% {
        opacity: 0;
    }
    44% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    46% {
        opacity: 1;
    }
    53% {
        opacity: 0;
    }
    55% {
        opacity: 0;
    }
    56% {
        opacity: 1;
    }
    59% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes snow {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }
    100% {
        background-position: 50000px -50000px, 10000px -20000px,
            -10000px -15000px;
    }
}
@-moz-keyframes snow {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }
    100% {
        background-position: 50000px 50000px, 10000px 20000px, -10000px 15000px;
    }
}
@-webkit-keyframes snow {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }
    100% {
        background-position: 50000px 50000px, 10000px 20000px, -10000px 15000px;
    }
}
@-ms-keyframes snow {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }
    100% {
        background-position: 50000px 50000px, 10000px 20000px, -10000px 15000px;
    }
}

@keyframes bouncing {
    0% {
        transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
    }

    100% {
        transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
    }
}

@-webkit-keyframes bouncy {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
    }
}
@keyframes bouncy {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    33% {
        transform: translatey(-5px);
    }
    66% {
        transform: translatey(5px);
    }
    100% {
        transform: translatey(0px);
    }
}
@keyframes floating {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}

@keyframes jump {
    from,
    20%,
    53%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@keyframes avatario {
    0%,
    100% {
        filter: hue-rotate(0deg) grayscale(0%) invert(0);
    }
    25% {
        filter: saturate(3);
    }
    26% {
        filter: saturate(0);
    }
    50% {
        filter: hue-rotate(20deg) grayscale(10%);
    }
}
.jump {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}
@media only screen and (orientation: portrait) {
    .rotate {
        transform: translateY(0);
    }
    .desktop {
        .fullScreen {
            .hippo-container {
                .child {
                    flex-direction: column;
                    min-height: auto;
                    .right {
                        width: 100%;
                    }
                    .three-in-a-row {
                        flex-direction: column;
                    }
                    .one-in-a-row {
                        img {
                            width: 80%;
                        }
                    }
                }
            }
            .parallax-cont {
                width: 100% !important;
                .parallax-right,
                .parallax-left,
                .parallax-left-bigger,
                .parallax-full-screen,
                .parallax-center,
                .parallax-right-bigger {
                    margin-left: 1% !important;
                    width: 98%;
                }
            }
        }
    }

    .nav {
        .left {
            padding-left: 20px;
            .dropdown {
                display: none;
            }
        }
    }
    .desktop {
        .fullScreen {
            .text-overlay {
                height: 200px;
                width: 100%;
                bottom: 0;
                top: auto;
                .title,
                .text {
                    padding: 0 20px;
                }
            }
        }
    }
    .handle {
        .radio,
        .red-button {
            display: none;
        }
    }
}

@media only screen and (orientation: landscape) and (max-width: 900px) {
    .ugly {
        width: 130px;
    }
    .desktop {
        .fullScreen {
            .hippo-container {
                .child {
                    flex-direction: column;
                    min-height: auto;
                    .right {
                        width: 100%;
                    }
                    .three-in-a-row {
                        flex-direction: column;
                    }
                    .one-in-a-row {
                        img {
                            width: 80%;
                        }
                    }
                }
            }
            .text-overlay {
                width: 300px;
            }
            .return {
                height: 40px;
                position: absolute;
                top: 20px;
                left: 20px;
                width: 40px;
                z-index: 3000;
                cursor: pointer;
            }
        }
    }

    .text-overlay {
        pointer-events: none;
        .on-point,
        .on-point-animation {
            pointer-events: all;
        }
    }

    .desktop .fullScreen .parallax-cont .parallax-center-text {
        display: block;
        width: 80%;
        margin-left: 10%;
        margin-top: -10%;
        text-align: center;
    }

    .desktop .mother .notebook-container,
    .desktop .father .notebook-container .desktop .mother .legal-container,
    .desktop .father .legal-container {
        height: 200px;
    }
    .handle {
        .red-folder img,
        .blue-folder img {
            pointer-events: none;
            width: 50px;
        }
        .title {
            margin-top: 0px;
        }
        .radio {
            img {
                width: 70px;
            }
        }
        .red-button,
        .bin {
            img {
                height: 70px;
            }
        }
    }
}
